.flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.inputStyle{
  font-size: 1.5em;
  padding: 3px;
  display: inline-block;
  margin-left: 5px;
  width: 30%;
}

button {
  cursor: pointer;
  margin-left: 5px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

img {
  height: 300px;
  object-fit: cover;
  width: 400px;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}